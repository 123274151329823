import {
  CashInterface,
  ChildTrustFundInterface,
  PortfolioDataInterface,
} from "@ifgengineering/hip-app-domain/src";
import { Asset, StandardAssets } from "./types";

// For some assets, we only get a quarter of their total value in consideration
const getZakatPart = (total: number) => total * 0.25;

const extractTotalCash = ({ totalCashValue }: CashInterface): number =>
  totalCashValue;

const extractTotalCTF = ({
  childTrustFundList,
}: ChildTrustFundInterface): number => {
  const total = childTrustFundList?.reduce((acc, item) => {
    return acc + item.totalValue;
  }, 0);

  return total || 0;
};

const extractTotalStandardAsset = ({ totalValue }: StandardAssets) =>
  totalValue;

type AssetTotalOutput = {
  total: number;
  zakat: number;
};

const getCashTotal = (asset: CashInterface): AssetTotalOutput => {
  const total = extractTotalCash(asset);
  const zakat = total;

  return { total, zakat };
};

const getChildTrustFundsTotal = (asset: Asset): AssetTotalOutput => {
  const total = extractTotalCTF(asset);
  const zakat = getZakatPart(total);

  return { total, zakat };
};

const getStandardAssetTotal = (asset: StandardAssets): AssetTotalOutput => {
  const total = extractTotalStandardAsset(asset);
  const zakat = getZakatPart(total);

  return { total, zakat };
};

const getNonZakatableAsetsTotal = (asset: StandardAssets): AssetTotalOutput => {
  const total = extractTotalStandardAsset(asset);
  const zakat = 0;

  return { total, zakat };
};

const getDefaultAssetTotal = (asset: StandardAssets): AssetTotalOutput => {
  const total = extractTotalStandardAsset(asset as StandardAssets);
  const zakat = total;

  return { total, zakat };
};

export const getAssetTotal = (
  assetKey: keyof PortfolioDataInterface,
  asset: Asset
): AssetTotalOutput => {
  switch (assetKey) {
    case "cash":
      return getCashTotal(asset as CashInterface);
    case "childTrustFunds":
      return getChildTrustFundsTotal(asset);
    case "stocks":
    case "funds":
    case "startups":
    case "pensions":
      return getStandardAssetTotal(asset as StandardAssets);
    case "properties":
    case "agricultureAssets":
      return getNonZakatableAsetsTotal(asset as StandardAssets);
    default:
      return getDefaultAssetTotal(asset as StandardAssets);
  }
};

const keyBlackList = ["estimatedEstateValue", "debts", "id", "currency"];

export const calculateWhatIOwn = (
  portfolio: PortfolioDataInterface
): AssetTotalOutput => {
  const assetKeys = Object.keys(portfolio).filter(
    (key) => !keyBlackList.includes(key)
  ) as Array<keyof PortfolioDataInterface>;

  const whatIOwn = { total: 0, zakat: 0 };

  assetKeys.forEach((assetKey) => {
    const { total, zakat } = getAssetTotal(
      assetKey,
      portfolio[assetKey] as Asset
    );

    whatIOwn.total = total + whatIOwn.total;
    whatIOwn.zakat = zakat + whatIOwn.zakat;
  });

  return whatIOwn;
};

export const calculateWhatIOwe = (
  portfolio: PortfolioDataInterface
): AssetTotalOutput => {
  const assetKeys = Object.keys(portfolio).filter(
    (key) => key === "debts"
  ) as Array<keyof PortfolioDataInterface>;

  const whatIOwn = { total: 0, zakat: 0 };

  assetKeys.forEach((assetKey) => {
    const { total, zakat } = getAssetTotal(
      assetKey,
      portfolio[assetKey] as Asset
    );

    whatIOwn.total = total + whatIOwn.total;
    whatIOwn.zakat = zakat + whatIOwn.zakat;
  });

  return whatIOwn;
};
