import { ZakatState } from "../reducers/reducers";

export const totalAllocatedSelector = (state: {
  zakat: ZakatState;
}): number => {
  const { charityAllocations } = state?.zakat || {
    charityAllocations: [],
  };

  return charityAllocations?.reduce((acc, val) => acc + val.percentage, 0);
};
