import { PortfolioDataInterface } from "@ifgengineering/hip-app-domain/src";
import {
  calculateWhatIOwe,
  calculateWhatIOwn,
} from "./calculatePortfolioValue";
import { ZAKAT_MULTIPLIER } from "./consts";
import { updatePortfolioValues } from "./updatePortfolioValues";

export type ZakatSummary = {
  zakatableAssets: number;
  whatIOwe: number;
  whatIOwn: number;
  nisab: number;
  zakat: number;
};

export const zakatCalculator =
  (goldPrice: number, silverPrice: number, nisabPrice?: number) =>
  (portfolio: PortfolioDataInterface): ZakatSummary => {
    const portfolioWithUpdatedValues = updatePortfolioValues(
      portfolio,
      goldPrice,
      silverPrice
    );

    const { total: whatIOwn, zakat: zakatableAssets } = calculateWhatIOwn(
      portfolioWithUpdatedValues
    );

    const { total: whatIOwe } = calculateWhatIOwe(portfolioWithUpdatedValues);

    const nisab = nisabPrice || silverPrice * 21;

    const zakat =
      whatIOwn - whatIOwe < nisab
        ? 0
        : (zakatableAssets - whatIOwe) * ZAKAT_MULTIPLIER;

    return {
      whatIOwe,
      whatIOwn,
      zakatableAssets,
      nisab,
      zakat,
    };
  };
